import { graphql, Link } from "gatsby"
import React, { useState } from "react"
import { Layout } from "../components/Layout"
import {
  ServiceSection,
  DemoButton,
  LearnMoreButton,
  GetStartedButton,
  CountsSection,
  Testimonials,
  Faq,
  AutoScrollBanner,
  TryIrisGPTForFreeBanner,
  FeatureSection,
  FeatureSectionRight,
} from "../commonComponents"
import { BlogItem, BlogPostData } from "../blogUtils"
import { Helmet } from "react-helmet"

export default function HealthcareSupportAI({ data }) {
    const blogsAll: BlogPostData[] = data.allContentfulBlogPost.nodes
    const blog1 = blogsAll.find(blog => blog.slug === "conversational-ai-for-healthcare-applications-in-customer-support")
    const blog2 = blogsAll.find(blog => blog.slug === "impact-of-ai-in-healthcare-a-critical-evaluation-of-both-sides-of-the-coin")
    const blogs = [blog1, blog2]
  return (
    <Layout
      keywords="healthcare ai agent assist, healthcare chatbot, health tech chatbot, healthcare conversational ai"
      title="Best AI Agent Assist and Chatbot for Healthcare Sector | IrisAgent"
      description="Transform healthcare and health tech customer experiences with AI-powered agile, effective and human-like automated responses."
    >
      <Helmet htmlAttributes={{ lang: 'en' }}>
        {" "}
        <link
          rel="canonical"
          href="https://irisagent.com/healthcare-support-ai/"
        />
      </Helmet>
      {/* <!-- ======= Hero Section ======= --> */}
      <section
        id="hero-new-design"
        className="agent-assistant-section-padding-top"
      >
        <div className="row" style={{ width: "100%", margin: 0 }}>
          <div
            className="col-md-6 section-padding-left"
            style={{ paddingLeft: "0" }}
          >
            <h1 className="title-support-new-design">
              Transform healthcare customer experiences with <span className="text-highlight">smart AI responses</span>
            </h1>
            <div className="description-support-new-design">
            <ul className="checkbox-list">
              <li style={{ paddingLeft: "10px" }}> Automate up to 60% of interactions by <br/>delivering 24X7 support to your customers</li>
              <li style={{ paddingLeft: "10px" }}> Enable virtual patient assistance  from treatment<br/> to insurance with GenAI bots</li>
              <li style={{ paddingLeft: "10px" }}> Assist frontline teams with relevant information</li>
            </ul>
            </div>
            <br />
            <div className="description-button-support-new-design">
              <LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />
            </div>
          </div>
          <div className="col-md-6 centered-image-wrapper" >
              <img
                src="/img/healthcare.png"
                alt="ai for healthcare"
                style={{ width: "92%"}}
              />
            </div>
        </div>
      </section>
      {/* <!-- End Hero --> */}

      <AutoScrollBanner />

{/*  ======= Cta Section =======  */}
      <TryIrisGPTForFreeBanner />
      {/*  End Cta Section  */}
      <br/><br/>
     <FeatureSection
            title="AI-powered automated workflows for your healthcare interactions"
            description=<span>
                  <ul className="checkbox-list">
                    <li style={{ paddingLeft: "20px" }}><a href="https://irisagent.com/support-agent-assist/">IrisGPT GenAI chatbot</a> using LLMs efficiently responds to 60%+ healthcare queries with human-like responses. Automate tasks like health insurance invoicing, scheduling appointments, hospital policies, patient refunds, etc. </li>
                    <li style={{ paddingLeft: "20px" }}> Automated workflows with AI driven intent detection of all interactions and efficiently route and resolve issues faster. No more inaccurate manual monitoring and routing for inbound enquiries! </li>
                   </ul>
                  </span>
            imageUrl="/img/healthcare_workflows.png"
            buttonLabel="BOOK A DEMO"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />

      <FeatureSectionRight
            title="Empower support teams and prevent escalations with real-time sentiment analysis"
            description=
                    <span>
                    <ul className="checkbox-list">
                      <li style={{ paddingLeft: "20px" }}>Give agents real-time insights from knowledge bases, and historical medical and ticketing data</li>
                      <li style={{ paddingLeft: "20px" }}> Discover at-risk tickets with automated customer sentiment impact analysis </li>
                      <li style={{ paddingLeft: "20px" }}> Get real-time alerts about trending product and customer experience issues. Custom-made and fine-tuned for Healthcare companies. </li>
                    </ul>
                    </span>
            imageUrl="/img/healthcare_sentiment.png"
            buttonLabel="GET STARTED FOR FREE"
            buttonURL="/get-a-demo/"
            imageWidth= "100%"
      />

      <section className="d-flex align-items-center  sub-section-2-container">
        <div style={{ width: "100%" }}>
          <div className="row">
            <SecurityCard
              title="Secure Hosting"
              body="IrisAgent SaaS platform is hosted in a secure cloud infrastructure"
              image="/img/security-2.svg"
            />
            <SecurityCard
              title="Encrypted Data"
              body=" All the data collection happens securely over SSL using REST APIs"
              image="/img/security-3.svg"
            />
            <SecurityCard
              title="Single Sign-On"
              body="We do not require additional login credentials. We authenticate through Google, Microsoft and Salesforce."
              image="/img/security-4.svg"
            />
          </div>
        </div>
      </section>


      {/* <!-- ======= Counts Section ======= --> */}
      <CountsSection theme="light" />
      {/* <!-- End Counts Section --> */}

                          <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="What is conversational AI in healthcare?"
                  answer="Conversational AI in healthcare uses artificial intelligence technologies, such as chatbots and virtual assistants, to facilitate interactive, human-like conversations between healthcare companies and their customers. These AI-powered systems are designed to understand and process natural language, allowing customers to perform tasks related to their healthcare service, get personalized medical/health advice, resolve queries, and receive instant support through text or voice commands. By automating customer service and enhancing user experience, conversational AI transforms how healthcare companies operate and interact with their customers, making healthcare more accessible, efficient, and personalized."
                />
                <Faq
                  id="faq2"
                  question="What is conversational healthcare support?"
                  answer="Conversational healthcare support is a customer service approach in healthcare that utilizes chatbots, voice assistants, and messaging platforms to provide a more interactive and personalized healthcare customer service experience. It allows customers to request claims, access insurance or medical account information, receive health advice, and get support through natural language conversations via text or voice. This method aims to make healthcare more accessible and convenient, enabling customers to receive the best healthcare advice seamlessly in real-time without needing to visit physical branches or navigate complex healthcare company websites."
                />
                <Faq
                  id="faq3"
                  question="How can AI be used in healthcare and medical services?"
                  answer="AI can be used in healthcare and the medical services industry to enhance customer service, improve security, and streamline operations. It enables personalized patient support through chatbots, insurance fraud detection with machine learning algorithms, and automated underwriting for quicker medical loan approvals. AI also optimizes back-office processes, like document verification and risk management, and offers insights for better decision-making through data analysis. This integration of AI in healthcare leads to more efficient operations, reduced costs, and an improved customer experience, making healthcare more accessible, secure, and tailored to individual needs."
                />
                <Faq
                  id="faq4"
                  question="How is ChatGPT used in the healthcare industry?"
                  answer="ChatGPT is used in the healthcare industry to provide automated customer support, handle inquiries, and offer personalized patient advice through natural language processing. It can answer questions, assist customers with invoice payments and claims, and guide users through various healthcare services, enhancing customer experience with its ability to understand and respond conversationally. Healthtech also utilizes ChatGPT for internal purposes, such as training staff or automating routine tasks, improving operational efficiency, and reducing the workload on human employees."
                />
                <Faq
                  id="faq5"
                  question="Can conversational AI handle complex patient requests?"
                  answer="Yes, advanced conversational AI systems are capable of handling complex patient requests, such as appointment scheduling, patient care management, paying insurance bills, or applying for medical loans, by securely processing user requests and integrating with the organization’s knowledge systems."
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- ======= Services Section ======= --> */}
      <ServiceSection />
      {/* <!-- End Services Section --> */}
      <div className="mb-5" style={{ marginTop: "3%", marginLeft: "8%", marginRight: "8%" }}>
      <div className="row">
      <div className="col-12 blog-continue-reading-title" style={{ marginBottom: "3%" }}>
          Check out more resources
        </div>
        {blogs.map(blog => (
              <BlogItem blog={blog} key={blog.slug} theme={"light"} />
            ))}
      </div>
      </div>


      {/* <!-- ======= Contact Services Section ======= --> */}
      {/* <ContactSection /> */}
      {/* <!-- End Contact Section --> */}
    </Layout>
  )
}
// todo: move to commonComponents as Security page also uses it
const SecurityCard = ({ title, body, image }: any) => {
  return (
    <div
      className="col-sm order-1 order-lg-1 "
      style={{ textAlign: "center", marginLeft: "10px", marginRight: "10px" }}
    >
      <img src={image} className=" sub-section-2-img" alt="secure" />
      <h4 className="sub-section-2-h">{title}</h4>
      <p className="sub-section-2-p">{body}</p>
    </div>
  )
}

export const query = graphql`
  query Blogs {
    allContentfulBlogPost(sort: { order: DESC, fields: createdAt }) {
      nodes {
        slug
        title
        createdAt(formatString: "MMM DD, YYYY")
        heroImage {
          file {
            url
          }
          title
        }
        summary {
          summary
        }
        tags
        blogContent {
          raw
        }
      }
    }
  }
`